<template>
  <div class="app-container">
    <SearchForm v-model="queryParams" @search="fetchData" />
    <el-button
      v-permission="['ld.add_ld']"
      type="primary"
      icon="el-icon-plus"
      @click="$refs.ruleConfig.handleEdit()"
      style="margin-bottom: 10px"
      >添加</el-button
    >
    <el-card>
      <el-table
        v-loading.body="tableLoading"
        :data="tableData"
        element-loading-text="加载中 ..."
        border
        header-align="center"
        highlight-current-row
      >
        <el-table-column
          label="抽奖ID"
          min-width="80px"
          prop="config_id"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="抽奖名称"
          min-width="200"
          prop="name"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="抽奖类型"
          min-width="200"
          prop="draw_type"
          align="center"
          header-align="center"
          :formatter="typeFormatter"
        />
        <el-table-column
          label="是否有效"
          min-width="120px"
          prop="effective"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <TqSwitch
              v-model="scope.row.is_active"
              confirm
              :tip="`是否使配置${scope.row.is_active ? '失效' : '有效'}`"
              @change="switchEnableHandle(scope.row)"
              :disabled="!permission"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="是否引用"
          min-width="120px"
          prop="used"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.used" type="success">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="创建时间"
          min-width="310"
          align="center"
          :formatter="timeFormatter"
        />

        <el-table-column
          label="创建人"
          min-width="80px"
          prop="creator"
          align="center"
          header-align="center"
        />

        <el-table-column
          label="操作"
          min-width="300px"
          align="center"
          header-align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.used"
              v-permission="['ld.view_ld']"
              type="text"
              size="small"
              @click="$refs.ruleConfig.handleEdit(scope.row.uuid)"
              >查看</el-button
            >
            <el-button
              v-else
              v-permission="['ld.modify_ld']"
              type="text"
              size="small"
              @click="$refs.ruleConfig.handleEdit(scope.row.uuid)"
              >修改</el-button
            >
            <el-button
              v-permission="['ld.manage_ldi']"
              type="text"
              size="small"
              @click="$refs.prizeConfig.handleEdit(scope.row.uuid, scope.row.used)"
              >奖项配置</el-button
            >
            <el-button
              v-permission="['ld.copy_ld']"
              type="text"
              size="small"
              @click="handleCopy(scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <pagination
          :total="count"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="fetchData"
        />
      </div>
    </el-card>

    <RuleConfig ref="ruleConfig" />
    <PrizeConfig ref="prizeConfig" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import TqSwitch from "@/components/TqSwitch";
import PrizeConfig from "./components/PrizeConfig";
import RuleConfig from "./components/RuleConfig";
import SearchForm from "./components/SearchForm.vue";
import { getLuckDrawList, updateEffectiveState, copyPrizeConfig } from "@/api/luckDraw";
export default {
  components: {
    TqSwitch,
    SearchForm,
    RuleConfig,
    PrizeConfig,
  },
  data() {
    return {
      count: 0,
      tableLoading: false,
      tableData: [],
      queryParams: {
        page: 1,
        size: 10,
        config_id: null,
        name: null,
        creator: null,
        draw_type: null,
        used: null,
        create_time: null,
      },
    };
  },
  computed: {
    permission() {
      return checkPermission(["ld.switch_ld"]);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const res = await getLuckDrawList(this.queryParams);
      this.tableData = res.data.results;
      this.count = res.data.count;
    },
    typeFormatter(row) {
      let typeName = "";
      switch (row.draw_type) {
        case 1:
          typeName = "九宫格";
          break;
        case 2:
          typeName = "大转盘";
          break;
        case 3:
          typeName = "砸金蛋";
          break;
        default:
          break;
      }
      return typeName;
    },
    timeFormatter(row) {
      return `${row.create_time}`;
    },
    async switchEnableHandle(row) {
      await updateEffectiveState(row.uuid);
      this.$message({
        showClose: true,
        message: "操作成功",
        type: "success",
      });
    },
    handleCopy(row) {
      this.$confirm(`奖品名称：【${row.name}】, 确定复制该奖项配置吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          copyPrizeConfig(row.uuid).then(() => {
            this.$message({
              showClose: true,
              message: "复制成功",
              type: "success",
            });
            this.fetchData();
          });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
