<template>
  <div class="table">
    <div class="title">{{ title }}</div>
    <div style="margin-bottom: 15px">
      <div class="select">
        <el-input
          placeholder="请输入搜索内容"
          clearable
          prefix-icon="el-icon-search"
          size="small"
          style="margin-top: 10px"
          v-model="keyword"
        ></el-input>
        <!-- <el-button
          v-if="title !== `已选${typeTitle}`"
          type="primary"
          size="mini"
          style="margin-top: 10px"
          @click="fetchUnselectedData"
          >筛选</el-button
        > -->
        <!-- <el-button
          v-if="title === `已选${typeTitle}`"
          type="primary"
          size="mini"
          style="margin-top: 10px"
          @click="fetchSelectedData"
          >筛选</el-button
        > -->
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      highlight-current-row
      :height="452"
      size="small"
      ref="multipleTable"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @current-change="handleCurrentChange"
    >
      <el-table-column v-if="multiple" type="selection" width="50" align="center"></el-table-column>
      <template v-if="dataType === 2">
        <el-table-column label="ID" width="100" prop="number"></el-table-column>
        <el-table-column
          label="课程名称"
          minWidth="140"
          prop="name"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="课程价格（元）" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.price | price }}</template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column label="ID" width="100">
          <template slot-scope="scope">{{ `CP${scope.row.number}` }}</template>
        </el-table-column>
        <el-table-column
          label="优惠券名称"
          minWidth="140"
          prop="name"
          show-overflow-tooltip
        ></el-table-column>
      </template>
    </el-table>
    <div style="margin-top: 10px">
      <pagination
        :total="tableType === 1 ? total : value.length"
        :page.sync="pagination.page"
        :limit.sync="pagination.size"
        @pagination="fetchUnselectedData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TableCol",
  data() {
    return {
      otherType: [],
      pagination: {
        page: 1,
        size: 10,
      },
      keyword: null,
      currentSelectedArr: [],
      courseTypeOptions: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableType: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: null,
    },
    dataType: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    typeTitle() {
      return this.dataType === 1 ? "优惠券" : "课程";
    },
    tableData() {
      if (this.tableType === 2) {
        return this.value.filter((val, index) => {
          if (index >= this.startIndex && index <= this.endIndex) {
            console.log(val);
            if (this.keyword) {
              if (val.number.indexOf(this.keyword) !== -1) {
                console.log("1", val);
                return val;
              }
            } else {
              return val;
            }
          }
        });
      }
      console.log("this.value", this.value);
      return this.value;
    },
    startIndex() {
      return (this.pagination.page - 1) * this.pagination.size;
    },
    endIndex() {
      return this.pagination.page * this.pagination.size - 1;
    },
  },
  watch: {
    keyword: {
      handler() {
        this.fetchUnselectedData();
      },
    },
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    // 获取待选择的数据
    fetchUnselectedData() {
      if (this.tableType === 1) {
        const queryParams = {
          keyword: this.keyword,
          ...this.pagination,
        };
        this.$emit("fetch-data", queryParams);
      }
    },
    // 获取已选择的数据
    // async fetchSelectedData() {
    //   this.tableData = this.keyword;
    //   console.log("111");
    // },
    handleSelect(selection, row) {
      if (this.multiple) {
        const set = new Set(this.currentSelectedArr);
        const isCheck = selection.find((item) => item.uuid === row.uuid);
        set[isCheck ? "add" : "delete"](row.uuid);
        this.currentSelectedArr = Array.from(set);
      }
    },
    handleSelectAll(selection) {
      if (this.multiple) {
        const set = new Set(this.currentSelectedArr);
        if (selection.length) {
          selection.forEach((item) => {
            set.add(item.uuid);
          });
        } else {
          this.list.forEach((item) => {
            set.delete(item.uuid);
          });
        }
        this.currentSelectedArr = Array.from(set);
      }
    },
    // 单选处理
    handleCurrentChange(currentRow) {
      if (currentRow && !this.multiple) {
        this.currentSelectedArr = Array.of(currentRow.uuid);
      }
    },
    clearMultipleSelection() {
      this.currentSelectedArr.length = 0;
      this.$refs.multipleTable.clearSelection();
    },
  },
};
</script>

<style scoped>
.table {
  width: 540px;
}
.title {
  font-size: 16px;
  margin-bottom: 1em;
}
.select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  flex-wrap: wrap;
}
</style>
