<template>
  <el-dialog title="抽奖规则配置" :visible.sync="dialogVisible">
    <el-form :model="formData" :rules="rules" ref="dataForm">
      <el-form-item label="抽奖名称" :label-width="formLabelWidth" prop="name">
        <div style="width: 360px">
          <el-input
            v-model="formData.name"
            :disabled="viewDisabled"
            maxlength="50"
            placeholder="请输入抽奖名称"
          />
        </div>
      </el-form-item>

      <el-form-item label="抽奖类型" :label-width="formLabelWidth" prop="draw_type">
        <el-select
          v-model="formData.draw_type"
          class="filter-item w150"
          clearable
          placeholder="请选择抽奖类型"
          :disabled="viewDisabled"
        >
          <el-option :key="1" label="九宫格" :value="1"></el-option>
          <!-- <el-option :key="2" label="大转盘" :value="2"></el-option>
          <el-option :key="3" label="砸金蛋" :value="3"></el-option> -->
        </el-select>
      </el-form-item>

      <el-form-item label="用户限制" :label-width="formLabelWidth" prop="user_limit">
        <el-radio-group v-model="formData.user_limit" :disabled="viewDisabled">
          <el-radio :label="1">新用户</el-radio>
          <el-radio :label="2">所有用户</el-radio>
          <el-radio :label="3">指定用户</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="showUserListUpload"
        label="上传名单"
        :label-width="formLabelWidth"
        prop="user_limit_urls"
      >
        <TqFileUpload v-model="formData.user_limit_urls" :limit="1" :template-url="templateUrl" />
      </el-form-item>

      <el-form-item
        v-if="formData.user_limit !== 1"
        label="商品限制"
        :label-width="formLabelWidth"
        prop="goods_limit"
      >
        <el-checkbox-group
          v-model="formData.goods_limit"
          @change="handleGoodsChange"
          :disabled="viewDisabled"
        >
          <el-checkbox :label="1">无限制</el-checkbox>
          <el-checkbox :label="2" :disabled="goodsCheckboxDisabled">按价格</el-checkbox>
          <el-checkbox :label="3" :disabled="goodsCheckboxDisabled">按支付时间</el-checkbox>
          <el-checkbox :label="4" :disabled="goodsCheckboxDisabled">支付指定课程</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        v-if="formData.goods_limit.indexOf(2) !== -1"
        label="价格区间"
        :label-width="formLabelWidth"
        class="custom-form-item"
        required
      >
        <el-form-item prop="price_range_start">
          <el-input-number
            v-model="formData.price_range_start"
            controls-position="right"
            :precision="2"
            :step="0.01"
            :min="0"
            :max="999999"
            :disabled="viewDisabled"
          />
        </el-form-item>
        &nbsp; ~ &nbsp;

        <el-form-item prop="price_range_end">
          <el-input-number
            v-model="formData.price_range_end"
            controls-position="right"
            :precision="2"
            :step="0.01"
            :min="0"
            :max="999999"
            :disabled="viewDisabled"
          />
        </el-form-item>
      </el-form-item>

      <el-form-item
        v-if="formData.goods_limit.indexOf(3) !== -1"
        label="下单时间"
        prop="time_range"
        :label-width="formLabelWidth"
      >
        <el-date-picker
          v-model="formData.time_range"
          type="datetimerange"
          range-separator="至"
          start-placeholder="上线日期"
          end-placeholder="下线日期"
          :default-time="defaultTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          :disabled="viewDisabled"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item
        v-if="formData.goods_limit.indexOf(4) !== -1"
        label="指定课程选择"
        :label-width="formLabelWidth"
        required
        prop="assign_courses"
      >
        <div>
          <el-button type="primary" plain @click="$refs.tqTableTransfer.handleShow(2)"
            >选择课程</el-button
          >
          <span class="f-grey f13 ml10">已选择{{ formData.assign_courses.length }}个课程</span>
        </div>
        <TqTableTransfer ref="tqTableTransfer" v-model="formData.assign_courses" />
      </el-form-item>

      <el-form-item label="固定综合中奖率" :label-width="formLabelWidth" required>
        <el-radio-group v-model="formData.is_lock_rate" :disabled="viewDisabled">
          <el-radio :label="true">固定</el-radio>
          <el-radio :label="false">不固定</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="抽奖频率"
        :label-width="formLabelWidth"
        class="custom-form-item"
        required
      >
        <el-form-item prop="frequency_type">
          <el-select
            v-model="formData.frequency_type"
            class="filter-item w150"
            clearable
            placeholder="请选择抽奖频率"
            :disabled="viewDisabled"
          >
            <el-option :key="1" label="每天" :value="1"></el-option>
            <el-option :key="2" label="每周" :value="2"></el-option>
            <el-option :key="3" label="每月" :value="3"></el-option>
            <el-option :key="4" label="活动期间" :value="4"></el-option>
            <!-- <el-option :key="5" label="自定义" :value="5"></el-option> -->
          </el-select>
        </el-form-item>
        &emsp;&emsp;
        <el-form-item label="参与次数" prop="frequency_number">
          <el-input-number
            v-model="formData.frequency_number"
            controls-position="right"
            :min="1"
            :max="99999"
            :disabled="viewDisabled"
          />
        </el-form-item>
      </el-form-item>

      <!-- <el-form-item label="虚拟中奖名单" :label-width="formLabelWidth">
        <TqFileUpload :disabled="viewDisabled" v-model="formData.virtual_user_file" :limit="1" />
      </el-form-item> -->
      <el-divider content-position="left">未满足抽奖条件的跳转目标配置</el-divider>
      <TqJumpType
        v-model="formData.jump_data"
        ref="tqJumpType"
        :disabled="viewDisabled"
        :course-multiple="false"
        :course-limite="1"
      />
      <el-form-item style="text-align: center">
        <el-button v-if="!viewDisabled" type="primary" @click="submit">保 存</el-button>
        <el-button v-if="viewDisabled" type="primary" @click="dialogVisible = false"
          >关 闭</el-button
        >
        <el-button v-if="!viewDisabled" @click="dialogVisible = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { getPrizeRule, savePrizeRule, updatePrizeRule } from "@/api/luckDraw";
import TqFileUpload from "@/components/TqFileUpload";
import TqTableTransfer from "@/components/TqTableTransfer";
import TqJumpType from "@/components/TqJumpType";

export default {
  components: {
    TqFileUpload,
    TqTableTransfer,
    TqJumpType,
  },
  computed: {
    showUserListUpload() {
      return this.formData.user_limit === 3;
    },
    goodsCheckboxDisabled() {
      return this.formData.goods_limit.some((x) => x === 1) || this.viewDisabled;
    },
  },
  watch: {
    "formData.time_range": {
      handler() {
        if (this.formData.time_range && this.formData.time_range.length > 0) {
          this.formData.purchase_time_start = this.formData.time_range[0];
          this.formData.purchase_time_end = this.formData.time_range[1];
        } else {
          this.formData.purchase_time_start = "";
          this.formData.purchase_time_end = "";
        }
      },
    },
    "formData.user_limit_urls": {
      handler(newV) {
        this.formData.user_limit_file = newV && newV.length > 0 ? newV[0] : "";
      },
    },
    "formData.user_limit": {
      handler(newV) {
        if (newV !== 3) {
          this.formData.user_limit_urls = [];
          this.formData.user_limit_file = "";
        }
        if (newV === 1) {
          this.formData.goods_limit = [];
          this.formData.price_range_start = null;
          this.formData.price_range_end = null;
          this.formData.purchase_time_start = null;
          this.formData.purchase_time_end = null;
          this.formData.assign_courses = [];
        }
      },
    },
    "formData.goods_limit": {
      handler(newV) {
        if (newV.indexOf(2) === -1) {
          this.formData.price_range_start = 0;
          this.formData.price_range_end = 0;
        }
        if (newV.indexOf(3) === -1) {
          this.formData.time_range = [];
        }
        if (newV.indexOf(4) === -1) {
          this.formData.assign_courses = [];
        }
      },
    },
  },
  data() {
    return {
      uuid: "",
      viewDisabled: false,
      formLabelWidth: "120px",
      defaultTime: ["00:00:00", "23:59:59"],
      dialogVisible: false,
      templateUrl: "https://oss.kaoyanvip.cn/attach/file1666667974164.csv",
      formData: this.initData(),
      rules: {
        name: [{ required: true, message: "请输入抽奖名称", trigger: "change" }],
        draw_type: [{ required: true, message: "请选择抽奖类型", trigger: "change" }],
        user_limit: [{ required: true, message: "请选择用户限制", trigger: "change" }],
        user_limit_urls: [{ required: true, message: "请上传用户名单", trigger: "change" }],
        goods_limit: [{ required: true, message: "请至少选择一个商品限制", trigger: "change" }],
        price_range_start: [{ required: true, message: "请输入价格区间", trigger: "change" }],
        price_range_end: [{ required: true, message: "请输入价格区间", trigger: "change" }],
        time_range: [{ required: true, message: "请选择下单时间", trigger: "change" }],
        assign_courses: [{ required: true, message: "请选择课程", trigger: "change" }],
        frequency_type: [{ required: true, message: "请选择抽奖频率", trigger: "change" }],
        frequency_number: [{ required: true, message: "请输入参与次数", trigger: "change" }],
      },
    };
  },
  methods: {
    initData() {
      return {
        name: "",
        used: false,
        draw_type: 1,
        user_limit: "",
        is_lock_rate: true,
        user_limit_urls: [],
        user_limit_file: "",
        goods_limit: [],
        price_range_start: 0,
        price_range_end: 0,
        time_range: [],
        purchase_time_start: "",
        purchase_time_end: "",
        assign_courses: [],
        frequency_type: "",
        frequency_number: 1,
        jump_data: { type: "", link: "", courses: [], classify: [] },
      };
    },
    async handleEdit(v) {
      this.uuid = v;
      this.formData = this.initData();
      if (v) await this.fetchData();
      this.$nextTick(() => {
        if (this.$refs["dataForm"]) {
          this.$refs["dataForm"].clearValidate();
          this.$refs["tqJumpType"].clearValidate();
        }
      });
      // 编辑/查看 模式
      this.viewDisabled = this.formData.used;
      this.dialogVisible = true;
    },
    async fetchData() {
      const result = await getPrizeRule(this.uuid);
      this.formData = { ...this.formData, ...result.data };
      this.formData.user_limit_urls.push(this.formData.user_limit_file);
      if (this.formData.purchase_time_start && this.formData.purchase_time_start) {
        this.formData.time_range = [
          this.formData.purchase_time_start,
          this.formData.purchase_time_end,
        ];
      }
      console.log("this.formData", this.formData);
    },
    async submit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          console.log("--valid-", valid);
          // 验证跳转数据
          Promise.all([this.$refs["tqJumpType"].validateFn()]).then(async (res) => {
            console.log("--res-", res);

            if (this.uuid) {
              await updatePrizeRule(this.uuid, this.formData);
            } else {
              await savePrizeRule(this.formData);
            }
            this.$parent.fetchData();
            this.dialogVisible = false;
            this.$parent.$message({
              message: this.uuid ? "修改抽奖规则成功" : "添加抽奖规则成功",
              type: "success",
            });
          });
        }
      });
    },
    handleGoodsChange() {
      if (this.goodsCheckboxDisabled) {
        this.formData.goods_limit = [1];
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/form.less");
</style>
