<template>
  <el-upload
    action
    class="upload-demo"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :before-remove="beforeRemove"
    multiple
    :limit="limit"
    :on-exceed="handleExceed"
    :http-request="customUpload"
    :file-list="fileList"
    :disabled="disabled"
  >
    <el-button :disabled="disabled" size="mini" type="primary" plain icon="el-icon-upload2"
      >点击上传</el-button
    >
    <el-button
      :disabled="disabled"
      size="mini"
      type="success"
      plain
      icon="el-icon-download"
      @click.stop="downloadHandler"
      >模板下载</el-button
    >
    <div v-if="tip" slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
  </el-upload>
</template>

<script>
import { upload } from "@/utils/upload";
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    limit: {
      type: Number,
      default: 1,
    },
    tip: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    templateUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newV) {
        // 只用于首次回显
        if (this.useFileList) {
          this.fileList = newV.map((item) => {
            const strArr = item.split("_");
            return { name: decodeURIComponent(strArr[2].split(".")[0]), url: item };
          });
          this.useFileList = false;
        }
      },
    },
  },
  data() {
    return {
      fileList: [],
      useFileList: true,
    };
  },
  methods: {
    customUpload(param) {
      upload(param.file).then((res) => {
        // 触发on-success事件
        param.onSuccess(res);
      });
    },
    handleSuccess(res, file, fileList) {
      this.handleData(fileList);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleRemove(file, fileList) {
      this.handleData(fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleData(v) {
      const fileList = v.map((item) => {
        // return { name: item.name, url: item.url || item.response.url };
        return item.url || item.response.url;
      });
      this.$emit("input", fileList);
    },
    downloadHandler() {
      window.location.href = this.templateUrl;
    },
  },
};
</script>

<style lang="less" scoped></style>
