<template>
  <div>
    <el-form ref="jumpFrom" :model="value" :rules="rules">
      <el-form-item label="跳转类型" prop="type" :label-width="formLabelWidth">
        <el-select
          v-model="value.type"
          placeholder="请选择"
          @change="handleChangeType"
          :disabled="disabled"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="value.type === 1"
        label="跳转链接"
        prop="link"
        :label-width="formLabelWidth"
      >
        <el-input :disabled="disabled" class="w500" type="text" v-model="value.link"></el-input>
      </el-form-item>

      <el-form-item
        v-if="value.type === 2"
        label="跳转课程"
        prop="courses"
        :label-width="formLabelWidth"
      >
        <div>
          <el-button type="primary" plain @click="$refs.tqTableTransfer.handleShow(2)"
            >选择课程</el-button
          >
          <span class="f-grey f13 ml10">已选择{{ value.courses.length }}个课程</span>
        </div>
        <TqTableTransfer
          ref="tqTableTransfer"
          v-model="value.courses"
          :multiple="courseMultiple"
          :disabled="disabled"
          :limit="courseLimite"
          :produc-type="producType"
        />
      </el-form-item>

      <el-form-item
        v-if="value.type === 3"
        label="跳转分类"
        prop="classify"
        :label-width="formLabelWidth"
      >
        <el-cascader
          class="w400"
          :disabled="disabled"
          ref="cusCascader"
          :options="categoryOptions"
          :props="{ checkStrictly: true }"
          clearable
          v-model="value.classify"
        ></el-cascader>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validateURL } from "@/utils/validate.js";
import { getCategory } from "@/api/common.js";
import TqTableTransfer from "@/components/TqTableTransfer";

export default {
  components: {
    TqTableTransfer,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: "",
          link: "",
          courses: [],
          classify: [],
        };
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    courseMultiple: {
      type: Boolean,
      default: true,
    },
    courseLimite: {
      type: Number,
      default: 0,
    },
    producType: {
      type: Number,
      default: null,
    },
  },
  created() {
    this.initData();
  },
  data() {
    return {
      formLabelWidth: "120px",
      typeOptions: [
        { label: "链接", value: 1 },
        { label: "课程", value: 2 },
        { label: "分类", value: 3 },
      ],
      categoryOptions: [],
      rules: {
        type: [{ required: true, message: "请选择跳转类型类型", trigger: "change" }],
        link: [
          { required: true, message: "请输入跳转类型", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (!validateURL(value)) {
                callback("请输入合法链接");
              }
              callback();
            },
          },
        ],
        courses: [{ required: true, message: "请选择课程", trigger: "change" }],
        classify: [{ required: true, message: "请选择跳转分类", trigger: "change" }],
      },
    };
  },
  methods: {
    initData() {
      getCategory({ is_enable: 1 }).then((res) => {
        this.categoryOptions = res.data;
      });
    },
    validateFn() {
      // this.$emit("input", this.value);
      return new Promise((resolve, reject) => {
        this.$refs["jumpFrom"].validate((valid) => {
          if (valid) {
            resolve(valid);
          } else {
            reject(valid);
          }
        });
      });
    },
    clearValidate() {
      this.$refs["jumpFrom"].clearValidate();
    },
    handleChangeType() {
      this.value.link = "";
      this.value.courses = [];
      this.value.classify = [];
      this.clearValidate();
    },
  },
};
</script>

<style></style>
