<template>
  <el-tabs
    v-model="currentTab"
    type="card"
    :editable="editable"
    @edit="handleTabsEdit"
    @tab-click="handleTabClick"
    :before-leave="beforeLeave"
    class="custom"
  >
    <el-tab-pane :key="item.name" v-for="item in value" :name="item.name">
      <span slot="label">{{ item.name }}</span>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    currentTab: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isMax() {
      return this.value.length === this.max;
    },
  },
  data() {
    return {
      needVerify: false,
    };
  },
  methods: {
    // 添加,删除tab页事件处理
    // 没个tab页内的表单内容,单独提交,所以在添加和切换表单的时候要进行表单验证
    // 根据验证结果判断是否要创建和切换tab页
    async handleTabsEdit(targetName, action) {
      if (this.isMax) {
        this.$message({
          message: "已到达最大数量, 无法添加!",
          type: "error",
        });
        return;
      }
      // 添加tab页
      if (action === "add") {
        // 验证当前页表单内容
        const submitRes = await this.$parent.$parent.submitHandle(2);
        this.needVerify = submitRes;
        if (submitRes) {
          let newTabName = `奖项${this.value.length + 1}-${new Date().getTime()}`;
          this.value.push({
            name: newTabName,
          });
          const newIndex = this.value.length - 1;
          const data = {
            action,
            newTabName,
          };
          this.$emit("change", newIndex, data);
        }
      }
      // 删除tab页
      console.log("targetName", targetName);
      if (action === "remove" && targetName === this.currentTab) {
        this.$confirm(`确定删除该【${targetName}】配置?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          const delIndex = this.value.findIndex((tab) => tab.name === targetName);
          await this.$parent.$parent.deleteHandle(delIndex);
          this.value.splice(delIndex, 1);
          this.$emit("input", this.value);
          const showIndex = delIndex - 1;
          this.$emit("change", showIndex, action);
        });
      }
    },
    async handleTabClick({ index, name }) {
      await this.$parent.$parent.submitHandle(2);
      this.needVerify = true;
      // if (submitRes) {
      this.$emit("click", Number(index), name);
      // }
    },
    beforeLeave() {
      const tmp = this.needVerify;
      this.needVerify = false;
      return tmp;
    },
  },
};
</script>

<style lang="less" scoped>
.custom {
  ::v-deep .el-tabs__item.is-active {
    color: #409eff !important;
  }
  ::v-deep .el-tabs__item .el-icon-close {
    width: 0 !important;
  }
  ::v-deep .el-tabs__item.is-active.is-closable .el-icon-close,
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover .el-icon-close {
    width: 14px !important;
  }
}
</style>
