<template>
  <div class="filter-container">
    <el-card>
      <el-input
        v-model="value.config_id"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入抽奖ID"
      ></el-input>
      <el-input
        v-model="value.name"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入抽奖名称"
      ></el-input>
      <!-- 创建人 -->
      <RemoteSelect v-model="value.creator" />
      <el-select
        style="width: 150px"
        class="filter-item"
        v-model="value.draw_type"
        clearable
        placeholder="抽奖类型"
      >
        <el-option :key="1" label="九宫格" :value="1"></el-option>
        <!-- <el-option :key="2" label="大转盘" :value="2"></el-option>
        <el-option :key="3" label="砸金蛋" :value="3"></el-option> -->
      </el-select>
      <el-select
        style="width: 150px"
        class="filter-item"
        v-model="value.used"
        clearable
        placeholder="是否引用"
      >
        <el-option :key="1" label="是" :value="true"></el-option>
        <el-option :key="2" label="否" :value="false"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="$emit('search')"
        >搜索</el-button
      >
    </el-card>
  </div>
</template>

<script>
import RemoteSelect from "@/components/RemoteSelect";
export default {
  components: {
    RemoteSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style></style>
