<template>
  <el-upload
    :class="{ [className]: true, 'has-image': value, 'has-image-small': skin === 'small' }"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-change="handleUpload"
    drag
    action="#"
    :accept="accept"
    :disabled="disabled"
  >
    <img v-if="value" :src="value" class="img" />
    <template v-else>
      <i v-if="skin === 'small'" class="el-icon-plus uploader-icon"></i>
      <template v-else>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将图片拖到此处，或
          <em>点击上传</em>
        </div>
      </template>
    </template>
    <div class="el-upload__tip" slot="tip">
      只能上传jpg/png/gif文件，且不超过{{ fileSize ? fileSize : "3MB" }}
      <span v-if="tipSize">，图片尺寸：{{ tipSize }}</span>
    </div>
  </el-upload>
</template>
<script>
import { upload } from "@/utils/upload";
export default {
  name: "ImgUpload",
  data() {
    return {};
  },
  props: {
    className: String,
    tipSize: String,
    url: String,
    value: String,
    skin: String,
    fileSize: String,
    accept: {
      type: String,
      default: "image/gif, image/jpeg, image/png",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 3;
      const isLt2M = file.size / 1024 / 1024 < 2;
      const isLt50k = file.size / 1024 < 50;
      const isLt500k = file.size / 1024 < 500;
      console.log(file.size / 1024);
      if (this.fileSize == "50k" && !isLt50k) {
        !isLt50k && this.$message.error("上传图片大小不能超过 50k !");
        return false;
      }
      if (this.fileSize == "500k" && !isLt500k) {
        !isLt500k && this.$message.error("上传图片大小不能超过 500K !");
        return false;
      }
      if (this.fileSize == "2M" && !isLt2M) {
        !isLt2M && this.$message.error("上传图片大小不能超过 2M !");
        return false;
      }
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 3MB!");
        return false;
      }
      if (this.accept.indexOf(file.type) === -1) {
        this.$message.error("文件类型错误");
        return false;
      }
      return true;
    },
    handleUpload(file) {
      upload(file.raw)
        .then((res) => {
          let newValue = res.url;
          this.emitParent(newValue);
        })
        .catch((error) => {
          this.$message({
            message: error.msg,
            type: "error",
          });
        });
    },
    emitParent(newValue) {
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
  },
};
</script>
<style scoped lang="less">
.img {
  max-width: 100%;
  max-height: 100%;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
}

.has-image {
  /deep/ .el-upload-dragger {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.has-image-small {
  /deep/ .el-upload-dragger {
    width: 100px;
    height: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .el-icon-plus {
    width: 100px;
    height: 100px !important;
    line-height: 100px !important;
  }
}
</style>
